import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import homeLogo from "../../Assets/prog-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

const Home = () => {
    return (
        <section>
            <Container fluid className="home-section" id="home">
                <Particle/>
                <Container className="home-content">
                    <Row>
                        <Col md={7} className="home-header">
                            <h1 style={{paddingBottom: 15}} className="heading">
                                Hi There!{" "}
                                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
                            </h1>

                            <h1 className="heading-name">
                                I'M
                                <strong className="main-name"> ILYA AVERUK</strong>
                            </h1>

                            <div style={{padding: 30, textAlign: "left"}}>
                                <Type/>
                            </div>
                        </Col>

                        <Col md={5} style={{padding: 30}}>
                            <img
                                src={homeLogo}
                                alt="home pic"
                                className="img-fluid"
                                style={{maxHeight: "450px"}}
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Home2/>
        </section>
    );
}

export default Home;